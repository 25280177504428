import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { StyledPropsFix } from 'types';
import { Anchor } from 'utils';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';

const content = [
  {
    id: 1,
    title: 'What is tokenized real estate',
    anchor: 'what-is-tokenized-real-estate',
    color: '#3F826D',
    content: [
      'Tokenization, as it pertains to real estate, is the process of creating a virtual token that represents ownership of a type of real estate asset. As physical assets would back the tokens, the value of the tokens would fluctuate based on the performance of the asset. This is similar to traditional real estate investing but with the ease of transfer conferred by the utilization of blockchain technology.',
    ],
  },
  {
    id: 2,
    // eslint-disable-next-line quotes
    title: "What's STO",
    anchor: 'what-is-security-token-offering',
    color: '#4E90C4',
    content: [
      'STO stands for digital share offering. A digital share can be defined as a fungible, negotiable financial instrument that holds some type of monetary value, i.e., an investment product that is backed by a real-world asset such as a company or property.',
      'A digital share represents the ownership information of the investment product, recorded on a blockchain. When you invest in traditional stocks, for example, ownership information is written on a document and issued as a digital certificate (e.g. a PDF). For STOs, it’s the same process, but recorded on a blockchain and issued as a token.',
    ],
  },
  {
    id: 3,
    title: 'Future of digital shares on Coreestate',
    anchor: 'future-of-coreestate-tokens',
    color: '#3F2A62',
    content: [
      // eslint-disable-next-line quotes
      'We are constantly enhancing our services, including the development of new real estate projects and expansion of token use cases. In the near future, we envision Coreestate collaborating with leading digital asset exchange platforms to list our digital shares, allowing international investors outside our platform to trade them. Additionally, we are actively working on introducing new payment options such as fiat payments (credit card, bank transfers, etc.), and additional stablecoins and other digital currencies.',
    ],
  },
];

const InfoTitle = styled(Typography)`` as StyledPropsFix;
const InfoText = styled(Typography)`` as StyledPropsFix;
const ColorDivider = styled(Box)`` as StyledPropsFix;
const ContentBox = styled(Container)``;

const AboutTokenSection = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin: 0 0.3em;
  }

  .aboutTokenSection-contentBox {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 3.5em auto;
    background-color: ${({ theme }) => theme.palette.background.paper};
    padding: 0 3.3em 0 3em;
  }
  .aboutTokenSection-infoText {
    font-family: Roboto Regular;
    font-size: 1.1em;
    padding-top: 1em;
    margin: 0;
    color: ${({ theme }) => theme.palette.text.primary};

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 0.9em;
    }
  }
  .aboutTokenSection-infoTitle {
    font-family: Roboto Regular;
    font-size: 1.5em;
    margin: 0;
    color: ${({ theme }) => theme.palette.text.primary};

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1.1em;
    }
  }
  .aboutTokenSection-colorDivider {
    width: 0.9em;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
  }
`;

const GeneralLearnings = (): JSX.Element => {
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);

  return (
    <AboutTokenSection sx={{ maxWidth: `${sizeOfGrid}px` }}>
      {content.map((card) => (
        <ContentBox className="aboutTokenSection-contentBox" key={card.id}>
          <Anchor id={card.anchor} />
          <ColorDivider
            className="aboutTokenSection-colorDivider"
            backgroundColor={card.color}
          />
          <InfoTitle className="aboutTokenSection-infoTitle" component="h1">
            {card.title}
          </InfoTitle>
          <InfoText component="div">
            {card.content.map((content, index) => (
              <p className="aboutTokenSection-infoText" key={index}>
                {content}
              </p>
            ))}
          </InfoText>
        </ContentBox>
      ))}
    </AboutTokenSection>
  );
};

export default GeneralLearnings;
