import React from 'react';
import Main from 'layouts/Main';
import Container from 'components/Container';
import { AboutToken, GeneralLearnings } from './components';
import { useTheme } from '@mui/material/styles';

const LearnView = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Main bgcolor={theme.palette.background.level1} sectionTitle="Learn">
      <Container>
        <AboutToken />
      </Container>
      <Container
        backgroundColor={theme.palette.background.paper}
        maxWidth="100%"
        display="flex"
        justifyContent="center"
      >
        <GeneralLearnings />
      </Container>
    </Main>
  );
};

export default LearnView;
