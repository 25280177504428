import React from 'react';
import Learn from 'views/Learn';
import { SEO as Head } from 'components/seo';

const LearnPage = (): JSX.Element => {
  const seo = {
    title: 'How to invest in tokenized real estate',
    description:
      'Learn step-by-step how to navigate tokenized real estate investment and explore the benefits of fractional ownership. Start your journey towards financial growth and property ownership today',
    pathname: '/learn',
  };
  return (
    <>
      <Head
        title={seo.title}
        description={seo.description}
        pathname={seo.pathname}
      />
      <Learn />
    </>
  );
};

export default LearnPage;
